import {
    Button,
    Grid,
    Box
} from "@cloudscape-design/components";
import { useMemo, useState } from 'react';
import axios from 'axios';
// import { Storage } from 'aws-amplify';
import countryList from "react-select-country-list";

import consoleOnlyLocally from '../../utils/consoleOnlyLocally';
// import dataTHECOMPANY from "../../853448868.json"
import LoadingPage from '../../loading/Loading';
import CustomInput from "../customs/CustomInput";
import { getUTCDateNow } from "../../utils/dateUtils";


export default function SearchSiren ({ setRegistrationNumber, setRegistrationOffice, setCompanyLegalName, setCodeNaf, setBusinessSector, setEquity, 
    setDateCreated, setAddress, setAddressComplement, setCity, setZipCode, setCountry, setHasSearchSiren }) {
    
    const countryOptions = useMemo(() => countryList().getData(), []);

    let [inputValue, setInputValue] = useState("");
    let [isSearching, setIsSearching] = useState(false);
    let [errorSiren, setErrorSiren] = useState(null);

    async function searchSiren(siren) {
        const input = { siren: siren };
        var apiToken = process.env.REACT_APP_API_TOKEN_PAPPERS;
        var endpoint = `https://api.pappers.fr/v2/entreprise?api_token=${apiToken}&siren=${siren}`
        
        const config = {
            method: 'GET',
            url: endpoint,
            body: JSON.stringify(input),
            headers: {
              'Content-Type': 'application/json'
            }
        };

        try {
            var response = await axios(config);
            consoleOnlyLocally(response);
            
            return response.data;

        } catch (error) {
            return { error };
        }

    }

    function validateSirenFormat(siren) {

        if (siren.length !== 9) {
            setErrorSiren('SIREN must be 9 digits long');
            return false;
        }

        if (!/^\d+$/.test(siren)) {
            setErrorSiren('SIREN must contain only digits');
            return false;
        }

        consoleOnlyLocally('SIREN format is valid');
        setErrorSiren(null);
        return true;
    }

    async function saveJsonToS3 (sirenNumber, data) {
        // Write JSON string to a file in S3 bucket
        const timestamp = getUTCDateNow().toISOString().replace(/:/g, '-');

        Storage.put(`${sirenNumber}_${timestamp}.json`, data)
            .then(result => {
                consoleOnlyLocally('JSON data has been written to data.json in S3 bucket', result);
            })
            .catch(error => {
                consoleOnlyLocally('Error writing JSON data to S3 bucket', error);
            });
    }

    const handleSearchSiren = async (e) => {
        e.preventDefault();

        setIsSearching(true); 

        if (!validateSirenFormat(inputValue)) {
            setIsSearching(false); 
            return;
        }

        const result = await searchSiren(inputValue);
        consoleOnlyLocally("result");
        consoleOnlyLocally(result);

        if(result.error){
            setErrorSiren('SIREN not found');
            setIsSearching(false);
            setHasSearchSiren(true);
            return;
        }
        // saveJsonToS3(inputValue, result)

        // const result = dataTHECOMPANY;
        // consoleOnlyLocally(result);

        var registrationNumber = result.siren;
        var registrationOffice = result.greffe;
        var companyLegalName = result.nom_entreprise;
        var codeNaf = result.code_naf;
        var businessSector = result.libelle_code_naf;
        var equity = result.capital;
        var companyDateCreated = result.date_creation;
        
        var address = result.siege.adresse_ligne_1;
        var addressComplement = result.siege.adresse_ligne_2;
        var city = result.siege.ville;
        var zipCode = result.siege.code_postal;
        var countryString = result.siege.pays;

        // Find in options, the country object that match the countryString as label
        var country = countryOptions.find((c) => c.label === countryString);
      
        setRegistrationNumber(registrationNumber)
        setRegistrationOffice(registrationOffice)
        setCompanyLegalName(companyLegalName)
        setCodeNaf(codeNaf)
        setBusinessSector(businessSector)
        setEquity(equity)
        setDateCreated(companyDateCreated)
        setAddress(address)
        setAddressComplement(addressComplement)
        setCity(city)
        setZipCode(zipCode)
        setCountry(country)
        
        setHasSearchSiren(true);
        setErrorSiren(null);
        setIsSearching(false); 
    }


    return (isSearching ? <LoadingPage /> : 
        <Grid 
            gridDefinition={[
                { colspan: { default: 12, xxs: 9}},
                { colspan: { default: 12, xxs: 3}},
            ]}
        >
            <Box>
                <CustomInput
                    label="Recherche du SIREN de l'entreprise"
                    placeholder={"Indiquez le siren de l'entreprise recherchée"}
                    value={inputValue}
                    setValue={setInputValue}
                    type="number"
                />
                {errorSiren && (<div className="text-warning">{errorSiren}</div>)}
            </Box>
            <Box margin={{ top: 'xl'}}>
                <Button variant="primary" onClick={handleSearchSiren}>{"Rechercher"}</Button>
            </Box>
                
        </Grid>
    )
};
import { Grid } from "@cloudscape-design/components";
import CustomInput from "../customs/CustomInput";
import CustomInputDate from "../customs/CustomInputDate";
import CustomSelect from "../customs/CustomSelect";
import {LIST_TAX_OPTIONS} from "../../constants/global_lists";


export default function CompanyInfoForm({ companyLegalName, setCompanyLegalName, registrationNumber, setRegistrationNumber, 
    registrationOffice, setRegistrationOffice, codeNaf, setCodeNaf, businessSector, setBusinessSector, 
    equity, setEquity, dateCreated, setDateCreated, taxation, setTaxation, hasSearchSiren}) {

    return(hasSearchSiren && (
    <Grid
        gridDefinition={[
            { colspan: { default: 12, xxs: 4}},
            { colspan: { default: 12, xxs: 4}},
            { colspan: { default: 12, xxs: 4}},
            { colspan: { default: 12, xxs: 2}},
            { colspan: { default: 12, xxs: 6}},
            { colspan: { default: 12, xxs: 4}},
            { colspan: { default: 12, xxs: 4}},
            { colspan: { default: 12, xxs: 4}},
        ]}
    >
        <CustomInput
            label="Legal Name"
            placeholder={"Nom légal de l'entreprise"}
            value={companyLegalName}
            setValue={setCompanyLegalName}
        />
        <CustomInput
            label="SIREN"
            placeholder={"Numéro de SIREN"}
            value={registrationNumber}
            setValue={setRegistrationNumber}
        />
        <CustomInput
            label="Greffe"
            placeholder={"Greffe"}
            value={registrationOffice}
            setValue={setRegistrationOffice}
        />
        <CustomInput
            label="Code NAF"
            placeholder={"Code NAF"}
            value={codeNaf}
            setValue={setCodeNaf}
        />
        <CustomInput
            label="Business Sector"
            placeholder={"Secteur d'activité"}
            value={businessSector}
            setValue={setBusinessSector}
        />
        <CustomInput
            label="Equity"
            placeholder={"Capital"}
            value={equity}
            setValue={setEquity}
        />
        <CustomInputDate
            label="Date Created"
            value={dateCreated}
            setValue={setDateCreated}
        />
        <CustomSelect
            name="Taxation (IS/IR)"
            selectOptions={LIST_TAX_OPTIONS}
            value={taxation}
            setValue={setTaxation}
        />

        {/* <CustomInput
            label="Date Created"
            placeholder={"Date de création"}
            value={dateCreated}
            setValue={setDateCreated}
        /> */}
    </Grid>)
    );
}
import { OPTION_ENTREPRISE } from "../constants/global_lists";
import { createAccount } from "../services/calls/account";
import { createUser } from "../services/calls/user";
import consoleOnlyLocally from "../utils/consoleOnlyLocally";
import { getUTCDateFromISOStringDate } from "../utils/dateUtils";


export default async function addAccountAction({ data }) {
    
    // CREATE Acccount
    const accountData = {
        name : data.name,
        compteType : data.compteType,
        clientType : data.clientType,
        isAuthorized : data.isAuthorized,

        address: data.address,
        addressComplement: data.addressComplement,
        city: data.city,
        zipCode: data.zipCode,
        country: data.country,

        bankIban: data.bankIban,
        bankBic: data.bankBic,
        bankName: data.bankName,
        bankVerified: data.bankVerified,
        taxation: data.taxation,
    }

    // Add info of the Company if it is a company
    if (data.compteType.label === OPTION_ENTREPRISE.label){
        accountData.registrationNumber = data.registrationNumber;
        accountData.registrationOffice = data.registrationOffice;
        accountData.companyLegalName = data.companyLegalName;
        accountData.codeNaf = data.codeNaf;
        accountData.businessSector = data.businessSector;
        accountData.equity = data.equity;
        accountData.dateCreated = getUTCDateFromISOStringDate(data.dateCreated);
    }
    // Otherwise, add info of the Person
    else{
        accountData.firstname = data.firstname;
        accountData.lastname = data.lastname;
        accountData.birthdate = getUTCDateFromISOStringDate(data.birthdate);
        accountData.nationality = data.nationality;
        accountData.fiscalResidency = data.fiscalResidency;
    }
    
    consoleOnlyLocally(accountData);

    const responseAccount = await createAccount(accountData);
    const newAccount = responseAccount.account;
    consoleOnlyLocally(newAccount);

    // CREATE User
    const userData = {
        accountID: newAccount.accountID,
        username: data.username,
        email: data.email,
        password: data.password,
        firstname: data.firstname,
        lastname: data.lastname,
        birthdate: getUTCDateFromISOStringDate(data.birthdate),
        nationality: data.nationality,
        fiscalResidency: data.fiscalResidency
    }
    const newUser = await createUser(userData);

    consoleOnlyLocally(newAccount);
    consoleOnlyLocally(newUser);

}